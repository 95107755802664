.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes flash {
  0% {
    color: #e74c3c;
    font-size: 28px;
  }
  50% {
    color: #ecf0f1;
    font-size: 18px;
  }
  100% {
    color: #e74c3c;
    font-size: 28px;
  }
}


.marker {
  color: #ecf0f1;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  margin: 4px 2px;
  border-radius: 50%;
  animation: flash 1.5s ease-in-out infinite; /* Adjust duration as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow effect */
}